import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sponsorRoutes = {
  path: '/sponsors',
  component: DashboardLayout,
  redirect: '/sponsors/list',
  children: [
    {
      path: 'list',
      name: 'Sponsors List',
      component: () => import(/* webpackChunkName: "sponsors" */  '@/pages/sponsors/SponsorList'),
      meta: {
        permissionsCodes: ['sponsors/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Sponsor',
      component: () => import(/* webpackChunkName: "sponsors" */  '@/pages/sponsors/SponsorForm'),
      meta: {
        permissionsCodes: ['sponsors/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Sponsor',
      component: () => import(/* webpackChunkName: "sponsors" */  '@/pages/sponsors/SponsorForm'),
      meta: {
        permissionsCodes: ['sponsors/update'],
      }
    }
  ]
}

export default sponsorRoutes;
