import DashboardLayout from "@/pages/layouts/DashboardLayout";

let recordingRoutes = {
  path: '/recordings',
  component: DashboardLayout,
  redirect: '/recordings/list',
  children: [
    {
      path: 'list/:session_id',
      name: 'Recording List',
      component: () => import(/* webpackChunkName: "recordings" */  '@/pages/sessions/recordings/RecordingList'),
      meta: {
        permissionsCodes: ['recordings/index'],
      }
    },
    {
      path: 'create/:session_id',
      name: 'Add Recording',
      component: () => import(/* webpackChunkName: "recordings" */  '@/pages/sessions/recordings/RecordingForm'),
      meta: {
        permissionsCodes: ['recordings/create'],
      }
    },
    {
      path: ':session_id/edit/:id',
      name: 'Edit Recording',
      component: () => import(/* webpackChunkName: "recordings" */  '@/pages/sessions/recordings/RecordingForm'),
      meta: {
        permissionsCodes: ['recordings/update'],
      }
    }
  ]
}

export default recordingRoutes;
