import DashboardLayout from "@/pages/layouts/DashboardLayout";

let practicalMattersRoutes = {
    path: '/practical_matters',
    component: DashboardLayout,
    redirect: '/practical_matters/list',
    children: [
      {
        path: 'list',
        name: 'Practical Matter List',
        component: () => import(/* webpackChunkName: "practical_matters" */  '@/pages/general/practicalMatters/PracticalMatterList'),
        meta: {
          permissionsCodes: ['ar/practical_matters/index'],
        }
      },
      {
        path: 'create',
        name: 'Add Practical Matter Item',
        component: () => import(/* webpackChunkName: "practical_matters" */  '@/pages/general/practicalMatters/PracticalMatterForm'),
        meta: {
          permissionsCodes: ['ar/practical_matters/create'],
        }
      },
      {
        path: 'edit/:id',
        name: 'Edit Practical Matter Item',
        component: () => import(/* webpackChunkName: "practical_matters" */  '@/pages/general/practicalMatters/PracticalMatterForm'),
        meta: {
          permissionsCodes: ['ar/practical_matters/update'],
        }
      }
    ]
  }

  export default practicalMattersRoutes;
