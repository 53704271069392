<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['forums/index'])"
                      :link="{name: 'Editions', icon: 'nc-icon nc-map-big', path: '/forums'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['sessions/index','session-topics/index', 'session-days/index'])"
                      :link="{name: 'Sessions', icon: 'nc-icon nc-notes'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['sessions/index'])"
            :link="{name: 'Sessions', path: '/sessions/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['session-days/index'])"
            :link="{name: 'Session Days', path: '/session-days/list'}"></sidebar-item>
          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['session-topics/index'])"
            :link="{name: 'Session Topics', path: '/session-topics/list'}"></sidebar-item>

        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['virtual-courses/index'])"
                      :link="{name: 'Virtual Courses', icon: 'nc-icon nc-tablet-2', path: '/virtual-courses'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['speakers/index'])"
                      :link="{name: 'Speakers', icon: 'nc-icon nc-single-02', path: '/speakers'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['organizers/index'])"
                      :link="{name: 'Organizers', icon: 'nc-icon nc-bank', path: '/organizers'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['partners/index'])"
                      :link="{name: 'Partners', icon: 'nc-icon nc-chart-pie-35', path: '/partners'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['sponsors/index'])"
                      :link="{name: 'Golden Sponsors', icon: 'fas fa-user-friends', path: '/sponsors'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['local-hosts/index'])"
                      :link="{name: 'Local Hosts', icon: 'nc-icon nc-refresh-02', path: '/local-hosts'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['pre-works/index'])"
                      :link="{name: 'Pre-works', icon: 'nc-icon nc-refresh-02', path: '/pre-works'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['program-overview/index'])"
          :link="{name: 'Program Overview', icon: 'nc-icon nc-align-left-2', path: '/program-overview'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['practical_matters/index'])"
                      :link="{name: 'Practical Matters', icon: 'fas fa-info', path: '/practical_matters'}">
        </sidebar-item>




        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])"
                      :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>
<!--          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])"-->
<!--                        :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">-->
<!--          </sidebar-item>-->




        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index'])"
            :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index'])"
            :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>

        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index'])"
            :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index'])"
            :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index'])"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}">
        </sidebar-item>



        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/deleted-users')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/deleted-users')"
                        :link="{name: 'Deleted Users', path: '/users/DeletedUsersList'}"></sidebar-item>

        </sidebar-item>


      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
