import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sessionTopicRoutes = {
  path: '/session-topics',
  component: DashboardLayout,
  redirect: '/session-topics/list',
  children: [
    {
      path: 'list',
      name: 'Session Topic List',
      component: () => import(/* webpackChunkName: "sessionTopics" */  '@/pages/sessions/sessionTopics/SessionTopicList'),
      meta: {
        permissionsCodes: ['session-topics/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Session Topic',
      component: () => import(/* webpackChunkName: "sessionTopics" */  '@/pages/sessions/sessionTopics/SessionTopicForm'),
      meta: {
        permissionsCodes: ['session-topics/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Session Topic',
      component: () => import(/* webpackChunkName: "sessionTopics" */  '@/pages/sessions/sessionTopics/SessionTopicForm'),
      meta: {
        permissionsCodes: ['session-topics/update'],
      }
    }
  ]
}

export default sessionTopicRoutes;
