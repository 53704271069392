import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sessionDocumentRoutes = {
  path: '/sessions/documents',
  component: DashboardLayout,
  redirect: '/sessions/documents/list',
  children: [
    {
      path: 'list/:session_id',
      name: 'Document List',
      component: () => import(/* webpackChunkName: "documents" */  '@/pages/sessions/documents/DocumentList'),
      meta: {
        permissionsCodes: ['sessions/documents/index'],
      }
    },
    {
      path: 'create/:session_id',
      name: 'Add Document',
      component: () => import(/* webpackChunkName: "documents" */  '@/pages/sessions/documents/DocumentForm'),
      meta: {
        permissionsCodes: ['sessions/documents/create'],
      }
    },
    {
      path: ':session_id/edit/:id',
      name: 'Edit Document',
      component: () => import(/* webpackChunkName: "documents" */  '@/pages/sessions/documents/DocumentForm'),
      meta: {
        permissionsCodes: ['sessions/documents/update'],
      }
    }
  ]
}

export default sessionDocumentRoutes;
